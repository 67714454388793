<template>
    <div
        v-if="day < 7"
        class="w-[32%] mt-2 rounded-[12px] h-[100px] relative"
        :class="isToday && 'border-2 border-[#ffffff99]'"
        style="
            background: linear-gradient(
                92deg,
                rgba(250, 64, 157, 0.6) 0%,
                rgba(154, 47, 255, 0.6) 100%
            );
        "
    >
        <img
            :src="libIMG.TopCheckInItem"
            alt=""
            class="w-[70%] absolute top-0 left-1/2 -translate-x-1/2 z-20"
        />

        <div
            v-if="isPassed || !isToday"
            class="w-full h-full absolute top-0 left-0 z-30 rounded-[12px]"
            :class="[
                isPassed && 'bg-black/50',
                !isPassed && !isToday && 'bg-black/20',
            ]"
        ></div>

        <img
            v-if="isPassed"
            :src="libIMG.CheckYellow"
            alt=""
            class="w-[40%] absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 z-50"
        />

        <slot></slot>
        <div
            class="absolute top-0 left-0 w-full h-full flex flex-col justify-between items-center"
        >
            <h1 class="font-semibold text-[14px] text-white z-20">
                Day {{ day }}
            </h1>

            <div class="flex items-center justify-center mb-[6px]">
                <img
                    :src="libIMG.Logo"
                    alt="logo"
                    class="w-[16px] h-[16px] mr-1"
                    :class="checkPlatform !== 'PC' && '-mt-1'"
                />
                <h2 class="white-pink-neon-text font-neue-bold text-[16px]">
                    +{{ coin.toLocaleString() }}
                </h2>
            </div>
        </div>
    </div>

    <div
        v-else-if="day === 7"
        class="w-full rounded-[12px] h-[100px] relative mt-2"
        :class="isToday && 'border-2 border-[#ffffff99]'"
        style="
            background: linear-gradient(
                92deg,
                rgba(250, 64, 157, 0.6) 0%,
                rgba(154, 47, 255, 0.6) 100%
            );
        "
    >
        <div
            v-if="isPassed || !isToday"
            class="w-full h-full absolute top-0 left-0 z-40 rounded-[12px]"
            :class="[
                isPassed && 'bg-black/50',
                !isPassed && !isToday && 'bg-black/25',
            ]"
        ></div>
        <img
            :src="libIMG.TopCheckInItem"
            alt=""
            class="w-[25%] absolute top-0 left-1/2 -translate-x-1/2 z-20"
        />
        <h1
            class="font-semibold absolute top-0 left-1/2 -translate-x-1/2 text-[14px] text-white z-30"
        >
            Day 7
        </h1>
        <div
            class="absolute top-0 left-0 w-full h-full flex items-center justify-around"
        >
            <slot></slot>
            <div class="flex items-center justify-center">
                <img
                    :src="libIMG.Logo"
                    alt="logo"
                    class="w-[24px] h-[24px] mr-1"
                    :class="checkPlatform !== 'PC' && '-mt-1'"
                />
                <h2 class="white-pink-neon-text font-neue-bold text-[22px]">
                    +{{ coin.toLocaleString() }}
                </h2>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
import libIMG from "../constant/imgConstant";
import detectPlatform from "../utils/detectPlatform";
const checkPlatform = ref(detectPlatform());

const { isPassed } = defineProps({
    isToday: { type: Boolean, default: false },
    coin: { type: Number },
    day: { type: Number },
    isPassed: { type: Boolean, default: false },
});
</script>
