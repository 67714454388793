<template>
    <BackButton />
    <div class="dark">
        <div id="unity-container" class="unity-desktop">
            <canvas id="unity-canvas"></canvas>
        </div>
        <div id="loading-cover" style="display: none">
            <div id="unity-loading-bar">
                <div id="unity-logo flex items-center justify-center">
                    <img :src="libIMG.LogoGame" />
                </div>
                <div id="unity-progress-bar-empty" style="display: none">
                    <div id="unity-progress-bar-full"></div>
                </div>
                <div class="spinner"></div>
            </div>
        </div>
        <div id="unity-fullscreen-button" style="display: none"></div>
    </div>
</template>

<script setup>
import { onMounted } from "vue";
import { storeToRefs } from "pinia";
import libIMG from "../constant/imgConstant";
import { BackButton } from "../components";
import useUserAuthStore from "../stores/userAuthStore";

const userAuthStore = useUserAuthStore();
const { user } = storeToRefs(userAuthStore);
const hideFullScreenButton = "true";
const buildUrl = "/game/Build";
const loaderUrl = buildUrl + "/SpinGameBuild.loader.js";
const config = {
    dataUrl: buildUrl + "/SpinGameBuild.data.unityweb",
    frameworkUrl: buildUrl + "/SpinGameBuild.framework.js.unityweb",
    codeUrl: buildUrl + "/SpinGameBuild.wasm.unityweb",
    streamingAssetsUrl: "StreamingAssets",
    companyName: "DenkStudio",
    productName: "SpinGame",
    productVersion: "1.0",
};
const _reward = {
    last_coin: 0,
    current_coin: 0,
    last_energy: 0,
    current_energy: 0,
    multiplier: 0,
    coin_win: 0,
};

const canFullscreen = (function () {
    for (const key of [
        "exitFullscreen",
        "webkitExitFullscreen",
        "webkitCancelFullScreen",
        "mozCancelFullScreen",
        "msExitFullscreen",
    ]) {
        if (key in document) {
            return true;
        }
    }
    return false;
})();

onMounted(() => {
    const container = document.querySelector("#unity-container");
    const canvas = document.querySelector("#unity-canvas");
    const loadingCover = document.querySelector("#loading-cover");
    const progressBarEmpty = document.querySelector(
        "#unity-progress-bar-empty"
    );
    const progressBarFull = document.querySelector("#unity-progress-bar-full");
    const fullscreenButton = document.querySelector("#unity-fullscreen-button");
    const spinner = document.querySelector(".spinner");

    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
        container.className = "unity-mobile";
        config.devicePixelRatio = Math.min(config.devicePixelRatio, 2);
    }
    canvas.style.background =
        "url('" + buildUrl + "/SpinGameBuild.jpg') center / cover";
    loadingCover.style.display = "";

    const script = document.createElement("script");
    script.src = loaderUrl;
    script.onload = () => {
        createUnityInstance(canvas, config, (progress) => {
            spinner.style.display = "none";
            progressBarEmpty.style.display = "";
            progressBarFull.style.width = `${100 * progress}%`;
        })
            .then((unityInstance) => {
                loadingCover.style.display = "none";

                const event = new CustomEvent("login:invoke", {
                    detail: { customID: user.value.id.toString() },
                });

                window.dispatchEvent(event);

                if (canFullscreen) {
                    if (!hideFullScreenButton) {
                        fullscreenButton.style.display = "";
                    }
                    fullscreenButton.onclick = () => {
                        unityInstance.SetFullscreen(1);
                    };
                }
            })
            .catch((message) => {
                console.error(message);
            });
    };
    document.body.appendChild(script);
});

onMounted(async () => {
    window.addEventListener("spin:started", function (event) {
        _reward.last_coin = event.detail.currentScore;
        _reward.multiplier = event.detail.energyBet;
    });

    window.addEventListener("spin:end", async function (event) {
        if (event.detail.reward > 0) {
            _reward.current_coin = event.detail.currentScore;
            _reward.coin_win = event.detail.reward;
            try {
                await userAuthStore.saveScore(_reward);
            } catch (error) {
                console.error(error);
            }
        }
    });
});
</script>

<style scoped></style>
