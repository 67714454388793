<script setup>
import { useRouter } from "vue-router";
import { TonConnectUIProvider } from "@townsquarelabs/ui-vue";
import { onMounted } from "vue";

const baseURL = import.meta.env.VITE_BACKEND_URL;
const options = {
    manifestUrl: `${baseURL}/tonconnect-manifest.json`,
};
const router = useRouter();

onMounted(() => {
    // expand mini App web view to fullscreen
    window?.Telegram?.WebApp?.ready();
    window?.Telegram?.WebApp?.expand();
    //disable collapse
    window?.Telegram?.WebApp?.disableVerticalSwipes();

    window.addEventListener("vite:preloadError", (event) => {
        event.preventDefault();
        window.location.reload();
    });
});
</script>

<template>
    <TonConnectUIProvider :options="options">
        <component :is="router.currentRoute.value.meta.layout">
            <router-view />
        </component>
    </TonConnectUIProvider>
</template>
