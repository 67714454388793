<template>
    <div
        class="h-[calc(100svh-150px)] flex flex-col items-center justify-center relative has-top-border"
    >
        <div
            class="absolute top-[25%] left-[10%] w-[120px] h-[45px] cursor-pointer"
        >
            <div class="relative">
                <img :src="libIMG.Board" class="w-full aspect-auto" alt="" />

                <div
                    class="absolute w-full h-full top-0 left-0 p-[6px]"
                    @click.prevent="() => handleOnClick('amount')"
                >
                    <div class="flex items-center justify-between">
                        <h3 class="text-white text-[14px]">Point</h3>
                        <p class="text-white text-[14px]">
                            {{
                                items?.filter(
                                    (item) =>
                                        item.type === "amount" &&
                                        item.is_bought === true
                                ).length
                            }}/{{
                                items?.filter((item) => item.type === "amount")
                                    .length
                            }}
                        </p>
                    </div>
                    <div
                        class="flex items-center justify-center gap-[2px] mt-[6px]"
                    >
                        <div
                            v-for="(item, index) in items?.filter(
                                (item) => item.type === 'amount'
                            )"
                            :key="index"
                            class="item"
                            :class="
                                item.type === 'amount' &&
                                item.is_bought === true
                                    ? 'opacity-100'
                                    : 'opacity-40'
                            "
                        ></div>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="absolute top-[7%] left-1/2 -translate-x-1/2 w-[120px] h-[45px] cursor-pointer"
        >
            <div class="relative">
                <img :src="libIMG.Board" class="w-full aspect-auto" alt="" />

                <!--                TODO: rename food-->
                <div
                    class="absolute w-full h-full top-0 left-0 p-[6px]"
                    @click.prevent="() => handleOnClick('food')"
                >
                    <div class="flex items-center justify-between">
                        <h3 class="text-white text-[14px]">Crystals</h3>
                        <p class="text-white text-[14px]">
                            {{
                                items?.filter(
                                    (item) =>
                                        item.type === "food" &&
                                        item.is_bought === true
                                ).length
                            }}/{{
                                items?.filter((item) => item.type === "food")
                                    .length
                            }}
                        </p>
                    </div>
                    <div
                        class="flex items-center justify-center gap-[2px] mt-[6px]"
                    >
                        <div
                            v-for="(item, index) in items?.filter(
                                (item) => item.type === 'food'
                            )"
                            :key="index"
                            class="item"
                            :class="
                                item.type === 'food' && item.is_bought === true
                                    ? 'opacity-100'
                                    : 'opacity-40'
                            "
                        ></div>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="absolute top-[20%] right-[12%] w-[120px] h-[45px] cursor-pointer"
        >
            <div class="relative">
                <img :src="libIMG.Board" class="w-full aspect-auto" alt="" />

                <div
                    class="absolute w-full h-full top-0 left-0 p-[6px]"
                    @click.prevent="() => handleOnClick('storage')"
                >
                    <div class="flex items-center justify-between">
                        <h3 class="text-white text-[14px] capitalize">
                            Building
                        </h3>
                        <p class="text-white text-[14px]">
                            {{
                                items?.filter(
                                    (item) =>
                                        item.type === "storage" &&
                                        item.is_bought === true
                                ).length
                            }}/{{
                                items?.filter((item) => item.type === "storage")
                                    .length
                            }}
                        </p>
                    </div>
                    <div
                        class="flex items-center justify-center gap-[2px] mt-[6px]"
                    >
                        <div
                            v-for="(item, index) in items?.filter(
                                (item) => item.type === 'storage'
                            )"
                            :key="index"
                            class="item"
                            :class="
                                item.type === 'storage' &&
                                item.is_bought === true
                                    ? 'opacity-100'
                                    : 'opacity-40'
                            "
                        ></div>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="absolute bottom-[25%] left-[30%] w-[120px] h-[45px] cursor-pointer"
        >
            <div class="relative">
                <img :src="libIMG.Board" class="w-full aspect-auto" alt="" />

                <div
                    class="absolute w-full h-full top-0 left-0 p-[6px]"
                    @click.prevent="() => handleOnClick('speed')"
                >
                    <div class="flex items-center justify-between">
                        <h3 class="text-white text-[14px]">Speed</h3>
                        <p class="text-white text-[14px]">
                            {{
                                items?.filter(
                                    (item) =>
                                        item.type === "speed" &&
                                        item.is_bought === true
                                ).length
                            }}/{{
                                items?.filter((item) => item.type === "speed")
                                    .length
                            }}
                        </p>
                    </div>
                    <div
                        class="flex items-center justify-center gap-[2px] mt-[6px]"
                    >
                        <div
                            v-for="(item, index) in items?.filter(
                                (item) => item.type === 'speed'
                            )"
                            :key="index"
                            class="item"
                            :class="
                                item.type === 'speed' && item.is_bought === true
                                    ? 'opacity-100'
                                    : 'opacity-40'
                            "
                        ></div>
                    </div>
                </div>
            </div>
        </div>

        <CornerIcon
            :title="'Earn'"
            :position="'top-[10px] left-[10px]'"
            :path="COMING_NAME"
        >
            <img :src="libIMG.HallEarn" alt="hall earn" />
        </CornerIcon>

        <CornerIcon
            :title="'Boost'"
            :position="'top-[10px] right-[10px]'"
            :path="BOOST_NAME"
        >
            <img :src="libIMG.HallBoost" alt="hall boost" />
        </CornerIcon>

        <CornerIcon
            :title="'Airdrop'"
            :position="'bottom-[10px] left-[10px]'"
            :path="AIRDROP_NAME"
        >
            <img :src="libIMG.HallStar" alt="hall star" />
        </CornerIcon>

        <CornerIcon
            :title="'Ads'"
            :position="'bottom-[10px] right-[10px] '"
            :path="COMING_NAME"
        >
            <img :src="libIMG.HallAds" alt="hall ads" />
        </CornerIcon>
    </div>

    <Boost />
    <Airdrop />
</template>

<script setup>
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import useAuthStore from "../stores/userAuthStore";
import useShowStore from "../stores/showStore";
import libIMG from "../constant/imgConstant";
import { CornerIcon } from "../components";
import { BOOST_NAME, AIRDROP_NAME, COMING_NAME } from "../constant/showName";
import { Boost, Airdrop } from "../components";
import { FOOD_TYPE } from "../constant/itemType";

const router = useRouter();
const useAuth = useAuthStore();
const { items } = storeToRefs(useAuth);
const showStore = useShowStore();
const { showComing } = storeToRefs(showStore);

onMounted(async () => {
    if (!items.value) {
        await useAuth.getListItems();
    }
});

const handleOnClick = (typeName) => {
    if (typeName === FOOD_TYPE) {
        showComing.value = !showComing.value;
        return;
    }

    router.push({ path: `/items/${typeName}` });
};
</script>

<style scoped>
.item {
    border-radius: 1.59px;
    background: #fff;
    flex: 1;
    height: 6px;
    flex-shrink: 0;
}
</style>
