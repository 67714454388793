<template>
    <div class="w-full h-svh z-50" :class="showCalendar ? 'block' : 'hidden'">
        <div class="fixed top-0 w-full h-full left-0 main-bg blur-sm"></div>
        <div
            class="fixed top-0 left-0 w-full h-full fade-in flex flex-col items-center justify-center"
        >
            <img
                :src="libIMG.XClose"
                alt="x close"
                class="self-end cursor-pointer mb-6 mr-4"
                @click.prevent="handleOnclick"
            />
            <div
                class="relative w-[90%] rounded-3xl"
                :class="[checkPlatform === 'Android' ? 'h-[70%]' : 'h-[80%]']"
            >
                <img
                    :src="libIMG.BoostBG"
                    class="absolute top-0 left-0 w-full h-full"
                    alt=""
                />
                <img
                    :src="libIMG.Calendar"
                    class="w-[7%] absolute top-[-5%] left-[10%]"
                    alt=""
                />
                <img
                    :src="libIMG.Calendar"
                    class="w-[7%] absolute top-[-5%] right-[10%]"
                    alt=""
                />
                <div
                    class="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center"
                >
                    <h1 class="text-calendar">Daily Reward</h1>
                    <div
                        class="flex flex-wrap mt-2 justify-between px-2 items-center w-[95%]"
                    >
                        <CheckInItem
                            v-for="(item, index) in arrayDays"
                            :key="index"
                            :day="index + 1"
                            :coin="
                                index + 1 !== config?.checkin_max_days
                                    ? Number(config?.checkin_daily_reward)
                                    : Number(config?.checkin_max_days_reward)
                            "
                            :is-today="
                                user?.checked_in
                                    ? (user?.props?.days_checked_in %
                                          config?.checkin_max_days) -
                                          1 ===
                                          index ||
                                      (user?.days_checked_in %
                                          config?.checkin_max_days) -
                                          1 ===
                                          index
                                    : user?.props?.days_checked_in %
                                          config?.checkin_max_days ===
                                          index ||
                                      user?.days_checked_in %
                                          config?.checkin_max_days ===
                                          index
                            "
                            :is-passed="
                                user?.props?.days_checked_in %
                                    config?.checkin_max_days >
                                    index ||
                                user?.days_checked_in %
                                    config?.checkin_max_days >
                                    index
                            "
                        >
                            <img
                                :src="item.img"
                                alt="coin img"
                                :class="
                                    index === 6
                                        ? 'w-[35%] h-full'
                                        : 'absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2'
                                "
                            />
                        </CheckInItem>
                    </div>
                    <div class="w-[95%] h-[44px] px-2 mt-2">
                        <button
                            @click.prevent="handleCheckIn"
                            class="w-full h-full text-white font-neue-bold rounded-full flex items-center justify-center"
                            :class="user?.checked_in && 'opacity-50'"
                            style="
                                background: linear-gradient(
                                    92deg,
                                    #fa409d 0%,
                                    #9a2fff 100%
                                );
                            "
                            :disabled="user?.checked_in"
                        >
                            <div class="loader" v-if="isLoading"></div>
                            <p v-else>
                                {{
                                    user?.checked_in
                                        ? "You claimed today"
                                        : "Claim Reward"
                                }}
                            </p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { toast } from "vue3-toastify";
import { onMounted, ref } from "vue";
import libIMG from "../constant/imgConstant";
import useShowStore from "../stores/showStore";
import CheckInItem from "./CheckInItem.vue";
import useUserAuthStore from "../stores/userAuthStore";
import detectPlatform from "../utils/detectPlatform";
import arrayDays from "../constant/daysCheckin";
import { ID_TASK_CHECKIN } from "../constant/taskID";

const userAuthStore = useUserAuthStore();
const { user, config } = storeToRefs(userAuthStore);
const showStore = useShowStore();
const { showCalendar } = storeToRefs(showStore);
const checkPlatform = ref(detectPlatform());
const isLoading = ref();

const handleOnclick = async () => {
    showCalendar.value = false;
    handleCheckIn();
};

const handleCheckIn = async () => {
    if (user.value.checked_in || isLoading.value) {
        return;
    }

    try {
        isLoading.value = true;
        await userAuthStore.sendStartTask(ID_TASK_CHECKIN);
        await userAuthStore.sendCompleteTask(ID_TASK_CHECKIN);
        toast.success("Check in successfully");
        showCalendar.value = false;
    } catch (error) {
        console.error(error);
    } finally {
        isLoading.value = false;
    }
};

onMounted(async () => {
    if (!config.value) {
        await userAuthStore.getConfig();
    }
});
</script>

<style scoped>
.main-bg {
    background: rgba(0, 0, 0, 0.5);
}
.text-calendar {
    color: #fff;
    text-shadow: -0.414px -0.414px 12.426px #fa409d,
        1.243px 1.243px 20.71px rgba(255, 255, 255, 0.35);
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
}
</style>
