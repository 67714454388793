import { defineStore } from "pinia";
import {
    loginTelegram,
    getUserInfo,
    sendInviteCode,
    getLeaderBoardUser,
    getListInvitedFriend,
} from "../client/user.api";
import { startGame, claimGame, saveScore } from "../client/game.api";
import { checkIn } from "../client/checkIn.api";
import { upgradeLV } from "../client/boost.api";
import { getTasks, sendStartTask, sendCompleteTask } from "../client/task.api";
import { getListItems, buyItem } from "../client/item.api";
import _ from "lodash";
import { getConfig } from "../client/config.api";

const appDebugToken = import.meta.env.VITE_APP_DEBUG_TOKEN;

export default defineStore("auth", {
    state: () => ({
        user: null,
        token: null,
        game: null,
        tonBalance: 0,
        setTonBalance: false,
        tasks: null,
        items: null,
        listUsers: null,
        listInvitedFriend: null,
        isSetBoost: false,
        miningSpeedPerHour: 0,
        config: null,
    }),
    actions: {
        async getConfig() {
            if (!this.token) return;

            try {
                const response = await getConfig(this.token);
                if (response) {
                    this.config = response;
                }
            } catch (err) {
                console.error("Error config:", err);
            }
        },

        async login(credentials) {
            if (appDebugToken) {
                this.token = appDebugToken;
                return;
            }
            try {
                const response = await loginTelegram(credentials);
                if (response.token) {
                    this.token = response.token;

                    localStorage.setItem(
                        "token",
                        JSON.stringify(response.token)
                    );
                }
            } catch (error) {
                console.error("Login error: ", error);
            }
        },

        async getUserInfo(credentials) {
            try {
                const _token = JSON.parse(localStorage.getItem("token"));

                if (!_token) {
                    await this.login(credentials);
                } else {
                    this.token = _token;
                }

                if (this.user) {
                    return;
                }

                if (this.token) {
                    const response = await getUserInfo(this.token);
                    if (response) {
                        this.game = response?.game;
                        delete response?.game;
                        this.user = response;
                        return;
                    }
                }
            } catch (error) {
                console.error("Get user info error: ", error);
            }
        },

        async getListInvitedFriend(credentials) {
            try {
                if (!this.token) {
                    await this.login(credentials);
                }

                if (this.token) {
                    const response = await getListInvitedFriend(this.token);
                    if (response) {
                        this.listInvitedFriend = response;
                    } else {
                        this.listInvitedFriend = [];
                    }
                }
            } catch (error) {
                console.error("Get user info error: ", error);
            }
        },

        async checkIn() {
            try {
                const response = await checkIn(this.token);

                if (response) {
                    this.user = response.user;
                }
            } catch (error) {
                console.error("Login error: ", error);
            }
        },

        async startGame() {
            try {
                const response = await startGame(this.token);
                if (response) {
                    this.game = response?.data;
                    return true;
                }
            } catch (error) {
                console.error("Start game error: ", error);
            }
            return false;
        },

        async claimGame() {
            try {
                const response = await claimGame(this.token);
                if (response) {
                    this.user = response?.data?.user;
                    this.game = response?.data?.user?.game;
                    return true;
                }
            } catch (error) {
                console.error("Start game error: ", error);
            }
            return false;
        },

        async saveScore(reward) {
            try {
                const response = await saveScore(reward, this.token);
                if (response) {
                    this.game = response.game;
                    delete response.game;
                    this.user = response;
                    return true;
                }
            } catch (error) {
                console.error("Save score error: ", error);
            }
            return false;
        },

        async sendInviteCode(ref_code) {
            try {
                const response = await sendInviteCode(this.token, ref_code);
                if (response) {
                    delete response?.data?.game;
                    this.user = response?.data;
                    return true;
                }
            } catch (error) {
                console.error("Send invite code error: ", error);
            }
            return false;
        },

        async upgradeLV() {
            try {
                const response = await upgradeLV(this.token);
                if (response) {
                    if (this.game) {
                        this.game.level.level_number =
                            response?.data?.game?.level?.level_number;
                        this.game.level.upgrade_cost =
                            response?.data?.game?.level?.upgrade_cost;
                    }
                    delete response?.data?.game;
                    this.user = response?.data;
                    return true;
                }
            } catch (error) {
                console.error("Upgrade level error: ", error);
            }
            return false;
        },

        async getTasks() {
            try {
                const response = await getTasks(this.token);
                if (response) {
                    this.tasks = response?.data;
                    return true;
                }
            } catch (error) {
                console.error("Getting tasks error: ", error);
            }
            return false;
        },

        async sendStartTask(idTask) {
            try {
                const response = await sendStartTask(idTask, this.token);
                if (response) {
                    const task = _.find(this.tasks, {
                        id: response?.data?.task?.id,
                    });

                    if (!task) {
                        return false;
                    }
                    task.status = response?.data?.status;

                    return true;
                }
            } catch (error) {
                console.error("Getting tasks error: ", error);
            }
            return false;
        },

        async sendCompleteTask(idTask) {
            try {
                const response = await sendCompleteTask(idTask, this.token);
                if (response) {
                    const task = _.find(this.tasks, {
                        id: response?.data?.task?.id,
                    });

                    if (!task) {
                        return false;
                    }

                    task.status = response?.data?.status;
                    this.user = response?.data?.user;
                    return true;
                }
            } catch (error) {
                console.error("Getting tasks error: ", error);
            }
            return false;
        },

        async getListItems() {
            try {
                const response = await getListItems(this.token);
                if (response) {
                    this.items = response?.data;
                    return true;
                }
            } catch (error) {
                console.error("Getting list items: ", error);
            }
            return false;
        },

        async buyItem(itemId, type) {
            try {
                const response = await buyItem(itemId, this.token);

                if (response) {
                    this.user.balance = response.data?.balance;

                    this.game.started_at = response.data?.last_update_point_at;

                    const index = this.items?.findIndex(
                        (item) => item.id === itemId
                    );

                    if (index !== -1) {
                        this.items[index].is_bought = true;
                        switch (type) {
                            case "speed":
                                this.game.props.speed.value =
                                    this.items[index]?.value;
                                break;
                            case "amount":
                                this.game.props.amount.value =
                                    this.items[index]?.value;
                                break;
                            case "storage":
                                this.game.props.storage.value =
                                    this.items[index]?.value || Infinity;
                                this.game.props.storage.level =
                                    this.items[index]?.level;
                                break;
                            default:
                                break;
                        }
                    }
                    return true;
                }
            } catch (error) {
                console.error("Getting list items: ", error);
            }
            return false;
        },

        async getLeaderBoardUser() {
            try {
                const response = await getLeaderBoardUser(this.token);
                if (response) {
                    this.listUsers = response;
                    return true;
                }
            } catch (error) {
                console.error("Getting list items: ", error);
            }
            return false;
        },
    },
});
