<template>
    <div
        class="fixed flex items-center justify-center top-1 left-1/2 -translate-x-1/2 bg-white text-gray-600 font-semibold z-50 px-4 rounded-full py-[6px] text-[14px] cursor-pointer hover:bg-gray-300 hover:text-white transition-all"
        @click.prevent="handleOnclick"
    >
        <img
            :src="libIMG.ExitSVG"
            width="15"
            height="auto "
            alt=""
            class="mr-1"
        />
        Back
    </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import libIMG from "../constant/imgConstant";
const router = useRouter();

const handleOnclick = () => {
    router.push({
        path: "/home",
    });
};
</script>
