import { createApp } from "vue";
import { createPinia } from "pinia";
import Vue3Toasity from "vue3-toastify";
import * as Sentry from "@sentry/vue";
import "vue3-toastify/dist/index.css";
import "./assets/css/style.scss";
import "./assets/css/style_game.css";
import "./assets/css/odometer.css";
import router from "./router";
import App from "./App.vue";

const dsnSentry = import.meta.env.VITE_DSN_SENTRY;
const pinia = createPinia();

const app = createApp(App);

Sentry.init({
    app,
    dsn: dsnSentry,
    integrations: [
        Sentry.debugIntegration(),
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
        "localhost",
        /^https:\/\/staging\.monoland\.xyz\/api/,
    ],
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(router)
    .use(pinia)
    .use(Vue3Toasity, {
        autoClose: 1500,
        multiple: false,
    })
    .mount("#app");
