<template>
    <div
        class="sticky top-0 left-0 flex items-center justify-between w-full h-[60px] px-4"
    >
        <div class="flex items-center justify-center">
            <img
                :src="user?.avatar ? user?.avatar : libIMG.No_Avatar"
                class="rounded-lg w-[30px] h-[30px] mr-2"
            />
            <p class="text-white text-md font-neue-bold">
                {{ user?.full_name ? user?.full_name : "Username" }}
            </p>
        </div>

        <div class="flex items-center justify-center">
            <div class="parallelogram bg px-4 h-[34px]">
                <div
                    class="parallelogram-reverse flex items-center justify-center h-full"
                >
                    <img
                        :src="libIMG.Logo"
                        class="w-[12px] h-[12px] aspect-auto rounded-full mt-[-3px]"
                        alt="coin"
                    />
                    <p
                        class="leading-3 ml-[2px] text-[12px]"
                        v-if="miningSpeedPerHour"
                    >
                        <span class="font-neue-bold text-white">{{
                            formatNumber(miningSpeedPerHour)
                        }}</span>
                        <span class="text-white ml-1 opacity-45">/h</span>
                    </p>
                </div>
            </div>
            <div
                class="parallelogram bg px-3 h-[34px] text-white flex items-center ml-2 cursor-pointer"
                @click.prevent="handleOnclick"
            >
                <div
                    class="parallelogram-reverse flex items-center justify-center"
                >
                    <img :src="libIMG.Wallet" alt="wallet" class="w-9 h-9" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { computed, watch } from "vue";
import libIMG from "../constant/imgConstant";
import useUserAuthStore from "../stores/userAuthStore";
import formatNumber from "../utils/formatNumber";

const userAuthStore = useUserAuthStore();
const { user, miningSpeedPerHour, game } = storeToRefs(userAuthStore);
const _miningSpeedPerHour = computed(() => {
    return (
        Number(game.value?.props.speed.value) *
        Number(game.value?.props.amount.value) *
        3600 *
        user.value.boost
    );
});

const handleOnclick = () => {
    // TODO: tạm ẩn
    // showConnectTon.value = !showConnectTon.value;
};

watch(
    _miningSpeedPerHour,
    (p) => {
        if (!isNaN(p)) {
            miningSpeedPerHour.value = p;
        }
    },
    { immediate: true }
);
</script>
