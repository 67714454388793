<template>
    <div class="w-full h-svh z-50" :class="showAirdrop ? 'block' : 'hidden'">
        <div class="fixed top-0 w-full h-full left-0 main-bg blur-sm"></div>
        <div
            class="fixed top-0 left-0 w-full h-full fade-in flex flex-col items-center justify-center"
        >
            <div class="relative w-[90%] h-[75%] rounded-3xl">
                <img
                    :src="libIMG.BoostBG"
                    class="absolute top-0 left-0 w-full h-full"
                    alt=""
                />
                <img
                    :src="libIMG.XClose"
                    alt="x close"
                    class="absolute top-[-10px] right-[-4px] cursor-pointer z-[100]"
                    @click.prevent="handleOnclick"
                />
                <img
                    :src="libIMG.Coin5"
                    alt="x close"
                    class="absolute top-[-5px] right-[40px] cursor-pointer w-[75px] aspect-auto"
                /><img
                    :src="libIMG.Coin6"
                    alt="x close"
                    class="absolute top-[15px] right-[130px] cursor-pointer w-[30px] aspect-auto"
                /><img
                    :src="libIMG.Coin2"
                    alt="x close"
                    class="absolute top-[65px] right-[110px] cursor-pointer w-[30px] aspect-auto"
                />
                <img
                    :src="libIMG.Coin4"
                    alt="x close"
                    class="absolute top-[65px] right-[15px] cursor-pointer w-[30px] aspect-auto"
                />
                <div
                    class="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-between"
                >
                    <h1
                        class="white-pink-neon-text text-[30px] ml-6 mt-4 font-neue-bold self-start"
                    >
                        Airdrop
                    </h1>
                    <div
                        ref="swipeArea"
                        class="w-full flex flex-col items-center mt-6 h-[60%] overflow-auto"
                    >
                        <AirdropItem
                            v-for="item of topUsers.sort((a, b) => {
                                return a.top - b.top;
                            })"
                            :key="item.id"
                            :number="item.top"
                            :avatar="item.avatar"
                            :username="item.full_name"
                            :total-point="item.point.toString()"
                        />
                    </div>
                    <div
                        class="w-[calc(100%-12px)] h-[20%] mb-[6px]"
                        style="
                            border-radius: 0px 0px 25px 25px;
                            background: rgba(255, 255, 255, 0.03);
                            backdrop-filter: blur(20.799999237060547px);
                        "
                    >
                        <div
                            class="flex flex-col items-center justify-center h-full"
                        >
                            <div
                                v-if="currentUser"
                                class="flex items-center justify-center w-[90%] border-2 border-[#FA409D] rounded-2xl py-1"
                            >
                                <h1
                                    class="text-white font-neue-bold text-[14px] px-4"
                                    v-if="!currentUser.top"
                                >
                                    Unknown
                                </h1>
                                <h1
                                    class="text-white font-neue-bold text-[14px] px-4"
                                    v-else
                                >
                                    {{ currentUser.top }}
                                </h1>
                                <div
                                    class="w-full flex items-center justify-between"
                                >
                                    <div
                                        class="flex items-center justify-center"
                                    >
                                        <img
                                            :src="currentUser.avatar"
                                            alt="avatar"
                                            class="w-9 h-9 rounded-md mr-4"
                                        />
                                        <div class="flex flex-col">
                                            <p
                                                class="text-white text-[14px] font-semibold"
                                            >
                                                {{ currentUser.full_name }}
                                            </p>
                                            <p
                                                class="text-white/40 text-[14px]"
                                            >
                                                (You)
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        class="flex items-center justify-center mr-4"
                                    >
                                        <img
                                            :src="libIMG.Logo"
                                            alt="avatar"
                                            class="w-[18px] h-[18px] mt-[-2px] mr-1"
                                        />
                                        <p
                                            class="text-white text-[14px] font-semibold white-pink-neon-text"
                                        >
                                            {{
                                                formatNumber(
                                                    Number(currentUser.point)
                                                )
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <!--                        <button-->
                            <!--                            @click.prevent="handleConnectTon"-->
                            <!--                            class="text-white text-[14px] w-[90%] py-3 mt-2 rounded-full font-semibold"-->
                            <!--                            style="-->
                            <!--                                border: 1px solid rgba(255, 255, 255, 0);-->
                            <!--                                background: linear-gradient(-->
                            <!--                                    92deg,-->
                            <!--                                    #fa409d 0%,-->
                            <!--                                    #9a2fff 100%-->
                            <!--                                );-->
                            <!--                            "-->
                            <!--                        >-->
                            <!--                            Connect to your TON wallet to get Airdrop-->
                            <!--                        </button>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import libIMG from "../constant/imgConstant";
import useShowStore from "../stores/showStore";
import useUserAuthStore from "../stores/userAuthStore";
import { AirdropItem } from ".";
import formatNumber from "../utils/formatNumber";
import { computed, toValue, watch } from "vue";
import _ from "lodash";
import useCounterStore from "@/stores/counter.js";

const showStore = useShowStore();
const userAuthStore = useUserAuthStore();
const counterStore = useCounterStore();
const { showAirdrop } = storeToRefs(showStore);
const { totalCoin } = storeToRefs(counterStore);
const { user, listUsers } = storeToRefs(userAuthStore);
const topUsers = computed(() => toValue(listUsers)?.users || []);
const currentUser = computed(
    () => toValue(listUsers)?.current_rank || toValue(user)
);
const handleOnclick = () => {
    showAirdrop.value = false;
};

watch(showAirdrop, (isShow) => {
    if (!isShow) {
        return;
    }
    userAuthStore.getLeaderBoardUser();
})
watch(totalCoin, (newValue) => {
    if (listUsers.value?.current_rank) {
        listUsers.value.current_rank.point = newValue;
    }
    const currUser = _.find(listUsers.value?.users || [], {
        id: toValue(user).id,
    });
    if (currUser) {
        currUser.point = newValue;
    }
});
</script>

<style scoped>
.main-bg {
    background: rgba(0, 0, 0, 0.5);
}
</style>
